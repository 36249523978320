import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const productContent = [
  {
    description: `Akyga components brand was established in 2005 as a member of
                  the whole Akyga family. Firstly began its journey with primary and
                  rechargeable batteries and has since expanded its range to include a
                  variety of electronic components.`
  },
  {
    description: `Our inventory now features over 10,000 products, including Capacitors,
                  Resistors, Semiconductors and IC with new products continually being
                  developed.`
  },
  {
    description: `Our products are integral to a wide array of applications,
                  from IoT, Telemetric, Lighting to Automotive.`
  },
  {
    description: `At Akyga, each item is precisely manufactured and
                  undergoes rigorous quality testing to ensure superior
                  performance and reliability. We are committed to
                  innovation and excellence, driving our ongoing growth
                  and expansion in the industry.`
  }
];

const imagePaths = [
  '/assets/b1.png',
  '/assets/b2.png',
  '/assets/b3.png',
  '/assets/b4.png'
];

const ProductsComponent = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box paddingY={6}  bgcolor={theme.palette.primary.main} color="white">
      <Helmet>
        <title>{t('Products')}</title>
      </Helmet>
      <Grid container spacing={4} justifyContent="center">
        {productContent.map((item, index) => (
          <Grid key={index} item xs={12} sm={6} >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 400,
                lineHeight: 1.5,
                marginX: 'auto',
              }}
            >
              {t(item.description)}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={4} justifyContent="center" marginTop={4}>
        {imagePaths.map((src, index) => (
          <Grid key={index} item xs={12} sm={6} md={3} textAlign="center" justifyContent={"center"} alignContent={'center'}>
            <Box
              component="img"
              src={src}
              alt={`Product image ${index + 1}`}
              sx={{
                borderRadius: 2,
                width: '80%',
                // height: '230px',
                height: 'auto',
                marginBottom: theme.spacing(2),
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductsComponent;
