import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  useLocation 
} from "react-router-dom";
import React, { useEffect } from 'react';
import Page from './components/Page';
import Simple from './Pages/Configurator/Table/Simple';
import LandingPage from "./Pages/Landing";
import CatalougePage from "./Pages/Configurator";
import NotFound from './Pages/NotFound';
import ContactUs from './Pages/ContactUs';
import Basket from './Pages/Basket';
import QuoteSent from './Pages/QuoteSent';
import Certificates from './Pages/Certificates';
import CertificatesOld  from './Pages/CertificatesOld';
import Distributors from './Pages/Distributors';
import PrivacyPolicy from './Pages/PrivacyPolicy';

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />
  },
  {
    path: "/contact",
    element: <ContactUs />
  },
  {
    path: "/products/:id",
    element: <CatalougePage />
  },
  {
    path: "/products",
    element: <CatalougePage />
  },
  {
    path: "/products/:name/list",
    element: <Simple />
  },
  {
    path: "/basket",
    element: <Basket />
  },
  {
    path: "/quote-success",
    element: <QuoteSent />
  },
  {
    path: "/certificates",
    element: <Certificates />
  },
  {
    path: "/certificates_old",
    element: <CertificatesOld />
  },
  {
    path: "/distributors",
    element: <Distributors />
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />
  },
  {
    path: '*',
    element: <NotFound />
  },
]);

const AnalyticsTracker = ({ trackingId }) => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', trackingId, {
        page_path: location.pathname + location.search,
      });
    }
  }, [location, trackingId]);

  return null;
};


function App() {
  // const trackingId = 'G-ZQ6LM29RTB';

  // useEffect(() => {
  //   (function(c,l,a,r,i,t,y){
  //     c[a] = c[a] || function(){ (c[a].q = c[a].q || []).push(arguments) };
  //     t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/"+i;
  //     y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
  //   })(window, document, "clarity", "script", "okjt81zbsg");
  // }, []);

  return (
    <Page>
      <RouterProvider router={router}>
        {/* <AnalyticsTracker trackingId={trackingId} /> */}
      </RouterProvider>
    </Page>
  );
}

export default App;
