import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import Collapse from "@mui/material/Collapse";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Box, Grid, TablePagination, TableSortLabel } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Helmet } from 'react-helmet';
import { getBasketFromLocalStorage, saveBasketToLocalStorage } from "Utilities/localStorage";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const ProductsTable = ({ columns, filteredData }) => {
  const theme = useTheme();
  const [expandedRow, setExpandedRow] = useState(null);
  const [basket, setBasket] = useState(getBasketFromLocalStorage());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  useEffect(() => {
    saveBasketToLocalStorage(basket);
  }, [basket]);

  const handleRowClick = (rowId) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  const handleToggleQuote = (item, event) => {
    event.stopPropagation();
    const itemCode = item.itemcode;
    setBasket((prevBasket) => {
      const newBasket = { ...prevBasket };
      if (newBasket[itemCode]) {
        delete newBasket[itemCode];
      } else {
        const { itemcode, params } = item;
        const details = params.ParametersUI;
        const description = params.Description;
        const selectedDetails = {};
        columns.filter((col) => col.isMain).forEach((col) => {
          selectedDetails[col.name] = details[col.name];
        });
        newBasket[itemCode] = { itemCode, details: selectedDetails, description };
      }
      saveBasketToLocalStorage(newBasket);
      const basketEvent = new CustomEvent('basketChange', { detail: newBasket });
      window.dispatchEvent(basketEvent);
      return newBasket;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (column) => {
    // Only allow sorting if the column has a multiplier
    if (column.multipler) {
      const isAsc = orderBy === column.name && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(column.name);
    }
  };

  const applyMultiplier = (value, column) => {
    const multiplier = column.multipler || 1;
    return parseFloat(value) * multiplier;
  };

  const sortedData = [...filteredData].sort((a, b) => {
    if (!orderBy) return 0;

    const column = columns.find(col => col.name === orderBy);

    const aValue = applyMultiplier(a.params.ParametersUI[orderBy] || 0, column);
    const bValue = applyMultiplier(b.params.ParametersUI[orderBy] || 0, column);

    if (order === 'asc') {
      return aValue - bValue;
    } else {
      return bValue - aValue;
    }
  });

  const renderHeader = () => (
    <TableHead>
      <TableRow sx={{ bgcolor: "alternate.light" }}>
        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
          <Typography variant={"caption"} fontWeight={700} sx={{ textTransform: "uppercase" }}>
            ItemCode
          </Typography>
        </TableCell>
        {columns &&
          columns.filter((col) => col.isMain).map((column) => (
            <TableCell
              sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
              key={column.name}
              sortDirection={orderBy === column.name ? order : false}
            >
              {column.multipler ? (
                <TableSortLabel
                  active={orderBy === column.name}
                  direction={orderBy === column.name ? order : 'asc'}
                  onClick={() => handleSortRequest(column)}
                >
                  <Typography variant={"caption"} fontWeight={700} sx={{ textTransform: "uppercase" }}>
                    {column.name}
                  </Typography>
                </TableSortLabel>
              ) : (
                <Typography variant={"caption"} fontWeight={700} sx={{ textTransform: "uppercase" }}>
                  {column.name}
                </Typography>
              )}
            </TableCell>
          ))}
        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }} />
      </TableRow>
    </TableHead>
  );

  const renderCell = (item) => (
    <>
      <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }} component="th" scope="row">
        <Typography variant={"subtitle2"} fontWeight={700}>
          {item.itemcode}
        </Typography>
      </TableCell>
      {columns &&
        columns.filter((col) => col.isMain).map((column) => (
          <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }} component="th" scope="row" key={column.name}>
            <Typography variant={"subtitle2"} fontWeight={700}>
              {(item && item.params && item.params.ParametersUI) ? (item.params.ParametersUI[column.name] ?? "") : 'No Data'}
            </Typography>
          </TableCell>
        ))}
    </>
  );

  const renderBody = () => (
    sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => (
      <React.Fragment key={i}>
        <TableRow
          onClick={() => handleRowClick(i)}
          style={{ cursor: "pointer" }}
          sx={{ 
            "&:last-child td, &:last-child th": { border: 0 }, 
            bgcolor: basket[item.itemcode] 
                ? "primary.light" 
                : expandedRow === i 
                    ? "alternate.light" 
                    : "alternate.dark",
            backgroundImage: basket[item.itemcode] 
                ? "linear-gradient(90deg, rgba(220, 240, 255, 0.5) 0%, rgba(180, 210, 255, 0.3) 100%)" 
                : "none"
        }}        
        >
          {renderCell(item)}
          <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
            <Typography
              color={"primary"}
              variant={"subtitle2"}
              fontWeight={700}
              sx={{ cursor: "pointer" }}
              onClick={(event) => handleToggleQuote(item, event)}
            >
              {basket[item.itemcode] ? "Remove From Quote" : "Add To Quote"}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6969}>
            <Collapse in={expandedRow === i} timeout="auto" unmountOnExit>
              <ProductDetailsEnhanced item={item} columns={columns} />
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    ))
  );

  return (
    <>
      <Helmet>
        <title>Products - Our Offerings</title>
        <meta name="description" content="Browse our extensive list of products. Click on any product to see more details and add it to your quote list." />
      </Helmet>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 750 }} aria-label="Product Table">
          {renderHeader()}
          <TableBody>{renderBody()}</TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

export default ProductsTable;

const ProductDetailsEnhanced = ({ item, columns }) => {
  const itemDetails = item.params;

  const getGroupedColumns = (cols) => {
    const chunkSize = Math.ceil((cols.length - 1) / 2);
    return [cols.slice(1, chunkSize + 1), cols.slice(chunkSize + 1)];
  };

  const groupedColumns = getGroupedColumns(columns);

  return (
    <Box margin={2}>
      <Grid container spacing={2}>
        {/* Description and Image */}
        <Grid item xs={12} md={3}>
          <Typography gutterBottom variant="body2" component="div">
            <b>{itemDetails["Description"]}</b>
          </Typography>
          <Box mt={2}>
            {itemDetails["ImageUrl"] ? (
              <img
                src={itemDetails["ImageUrl"]}
                alt={itemDetails["PartNumber"]}
                style={{ maxWidth: "250px", margin: "16px 0" }}
              />
            ) : (
              <img
                src={"https://th.bing.com/th/id/OIP.gLIxUrBNj4TE3OEKt5N-2wHaGO?rs=1&pid=ImgDetMain"}
                alt={itemDetails["PartNumber"]}
                style={{ maxWidth: "250px", margin: "16px 0" }}
              />
            )}
          </Box>
        </Grid>

        {/* Parameter Groups */}
        {groupedColumns.map((group, groupIndex) => (
          <Grid item xs={12} md={3} key={groupIndex}>
            {group.map((column) => (
              <Typography
                sx={{ display: "flex", fontSize: "0.75rem" }}
                gutterBottom
                key={column.name}
              >
                <b style={{ marginRight: "8px", marginLeft: "4px" }}>
                  {column.name}:
                </b>
                {itemDetails.ParametersUI[column.name] ?? "No data"}
              </Typography>
            ))}
          </Grid>
        ))}

        {/* Certificates and Datasheets Combined */}
        <Grid item xs={12} md={3}>
          {itemDetails["Certificates"] && (
            <Box mb={2}>
              {renderCertificates(itemDetails["Certificates"])}
            </Box>
          )}
          {itemDetails["DataSheets"] && (
            <Box>
              {renderFiles(itemDetails["DataSheets"])}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const renderCertificates = (certificates) => {
  return (
    certificates && certificates.length > 0 &&
    <>
      <Typography sx={{ display: "flex", fontSize: "0.75rem" }} gutterBottom>
        <FileDownloadIcon sx={{ fontSize: "1.10rem", marginBottom: "0.5rem" }} /><b>Certificates:</b>
      </Typography>
      {certificates.map((cert) => (
        <Typography key={cert.label} sx={{ display: "flex", fontSize: "0.75rem", marginLeft: "0.5rem", marginBottom: "0.25rem" }} >
          <a href={cert.src}>{cert.label}</a>
        </Typography>
      ))}
    </>
  );
};

const renderFiles = (files) => {
  return (
    <>
      <Typography sx={{ display: "flex", fontSize: "0.75rem" }} gutterBottom>
        <FileDownloadIcon sx={{ fontSize: "1.10rem", marginBottom: "0.5rem" }} /><b>DataSheets:</b>
      </Typography>
      {files.map((file) => (
        <Typography key={file.label} sx={{ display: "flex", fontSize: "0.75rem", marginLeft: "0.5rem", marginBottom: "0.25rem" }} >
          <a href={file.src}>{file.label}</a>
        </Typography>
      ))}
    </>
  );
};
