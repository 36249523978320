import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const OurMission = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: theme.spacing(4),
        margin: '20px', 
        maxWidth: '993px', 
      }}
    >
      <Typography
        variant="h3"
        sx={{
            paddingBottom: theme.spacing(4),
        }}
      >
        Our Mission
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: '38px',
          fontWeight: 400,
          lineHeight: '43.7px',
        }}
      >
        Our goal is to strengthen{' '}
        <span style={{ color: '#0033A0' }}>Akyga's</span>{' '}
        brand position in the European market. We prioritize{' '}
        <span style={{ color: '#0033A0' }}>high quality and diversity</span>{' '}
        of our products to meet the expectations and needs of our customers. 
        We are extremely proud of our export branch in China, which opens 
        doors to global opportunities and enables us to build lasting, 
        international business partnerships.
      </Typography>
    </Box>
  );
};

export default OurMission;
