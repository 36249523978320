import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import { styled } from '@mui/system';

const timelineData = [
  { year: '2005', description: 'Akyga started production and distribution of Primary and Rechargeable Batteries.' },
  { year: '2009', description: 'Expanded offer with Film Capacitors.' },
  { year: '2016', description: 'Developed new products – THT Resistors.' },
  { year: '2019', description: 'Added Ceramic THT Capacitors to portfolio.' },
  { year: '2022', description: 'Rapidly growth in Semiconductors domain.' },
];


const CustomHorizontalTimeline = () => {
  return (
    <section class="timeline-wrapper">
        <div class="middle-line"></div>

        <div class="box box-top">
            <div class="box-content">
              <p class="box-year">2022</p>
                <p class="box-paragraf">
                Rapidly growth in <strong>Semiconductors</strong> domain.</p>
            </div>
          <div></div>
        </div>

        <div class="box box-bottom">
            <div class="box-content">
              <p class="box-year year-bottom">2019</p>
                <p class="box-paragraf-bottom">
                Added <strong>Ceramic THT Capacitors</strong> to portfolio.</p>
            </div>
          <div></div>
        </div>

        <div class="box box-top">
            <div class="box-content">
              <p class="box-year"> 2016</p>
              <p class="box-paragraf">
            Developed new products – <strong>THT Resistors.</strong>
        </p>
            </div>
          <div></div>
        </div>

        <div class="box box-bottom">
            <div class="box-content">
              <p class="box-year year-bottom">2009</p>
               <p class="box-paragraf-bottom">
               Expanded offer with <strong>Film Capacitors.</strong></p>
            </div>
          <div></div>
        </div>

        <div class="box box-top"> 
            <div class="box-content">
              <p class="box-year">2005</p>
              <p class="box-paragraf">
              Akyga started production and distribution of <strong>Primary and Rechargeable Batteries.</strong></p>
            </div>
          <div></div>
        </div>
    </section>
  );
};

export default CustomHorizontalTimeline;